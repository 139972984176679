import axios from './base';

const createVideo = () => axios.post(`/api/v1/signatures/video`, {}, {});

const createDoc = () => axios.post(`/api/v1/signatures/doc`, {}, {});

const postSignature = signature =>
  axios.post(`/api/v1/signatures`, signature, {});

const getSignatures = (userId, params) =>
  axios.get(`/api/v1/signatures/users/${userId}`, {
    params,
  });

const getSingleSignature = (userId, date) =>
  axios.get(`/api/v1/signatures/users/${userId}/dates/${date}`, {});

const getSignedDoc = async (params) => {
  const { project_id, ...queryParams } = params;
  const response = await axios.get(`/api/v1/signatures/projects/${project_id}`, {params: queryParams});
  return response;
};

const getDocByFormId = async (params) => {
  const { project_id, form_id } = params;
  const response = await axios.get(`/api/v1/signatures/projects/${project_id}/forms/${form_id}`);
  return response;
};


export {
  createVideo,
  createDoc,
  postSignature,
  getSignatures,
  getSingleSignature,
  getSignedDoc,
  getDocByFormId,
};

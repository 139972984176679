import Link from 'next/link';
import { useState } from 'react';
import PropTypes from 'prop-types';
import CustomButton from '@components/shared/CustomButton';
import CustomText from '@components/shared/CustomText';
import AppShowcase from '@components/AppShowcase';
import styles from './RenderForm.module.scss';
import { getDocByFormId } from '@api/lambda/signatures';
import { getUserData } from '@localStorage/userData';
import { useRouter } from 'next/router';
import { useMediaQuery } from 'react-responsive';

const RenderForm = ({ form }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
  const [isSigningAllowed, setIsSigningAllowed] = useState(true);
  const res = getUserData();
  const router = useRouter();

  const params = {
    project_id: res.project_id,
    form_id: `${res.project_id}_${res.form._id}`,
  };

  const handleStartDoc = async () => {
    try {
      const signedDocResponse = await getDocByFormId(params);
      const subjectsLength = res.form.subjects.length;

      if (signedDocResponse) {
        if (subjectsLength <= signedDocResponse.signatures.length && res.form.form_type === 'multiple_subject') {
          alert('Submission failed: All the people have already signed the documents. You cannot sign anymore documents!');
          setIsSigningAllowed(false);
          return;
        }
      }

      router.push('/new-signature');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <CustomText bold customStyle={styles.title}>
          {form?.form?.form_name}
        </CustomText>
        <div className={styles.box}>
          <CustomText customStyle={styles.label} bold>
            Language
          </CustomText>
          <CustomText customStyle={styles.info} bold>
            {form?.form?.language}
          </CustomText>
        </div>

        <div className={styles.buttonContainer}>
          <a
            href={form?.form?.url}
            target='_blank'
            rel='noreferrer noopener'
            className={styles.button}
          >
            <CustomButton text={isMobile ? 'Review' : 'Review Doc'} secondary />
          </a>
          <div className={styles.button}>
            <CustomButton text='Start Doc' onClick={handleStartDoc} disabled={!isSigningAllowed}/>
          </div>
        </div>
      </div>
    </div>
  );
};

RenderForm.propTypes = {
  form: PropTypes.shape({
    guestId: PropTypes.string.isRequired,
    jwt: PropTypes.string.isRequired,
    project_id: PropTypes.string.isRequired,
    form: PropTypes.shape({
      company: PropTypes.string,
      created_by: PropTypes.string,
      form_name: PropTypes.string,
      form_owner: PropTypes.string,
      form_type: PropTypes.string,
      hasGuardian: PropTypes.string,
      is_delete: PropTypes.string,
      is_template: PropTypes.string,
      language: PropTypes.string,
      maxHeight: PropTypes.string,
      maxWidth: PropTypes.string,
      subjects: PropTypes.array,
      url: PropTypes.string,
      user_id: PropTypes.string,
      _id: PropTypes.string,
    }).isRequired,
  }),
};
export default RenderForm;
